import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Button,
  Form,
  Alert,
  Icon
} from 'antd'

import API from '~/modules/API'

export default class Signup extends Component {
  static propTypes = {
    isGenericLink: PropTypes.bool,
    client: PropTypes.any,
    hash: PropTypes.any,
    isModal: PropTypes.any,
    cancel: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      isSubmitingLogin: false,
      isSubmitingRecover: false,
      loginEmail: '',
      loginPassword: '',
      loginValidation: false,

      isSubmitingSignup: false,
      signupName: '',
      signupTel: '',
      signupEmail: '',
      signupPassword: '',
      signupValidation: false,
    }

    if (props.client) {
      this.state.loginEmail = props.client.email
    }

    this.onChange = this.onChange.bind(this)
    this.setActionValidation = this.setActionValidation.bind(this)
  }

  onChange(value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }

    key === 'loginEmail' ? value = (value || '').toLowerCase() : null
    key === 'signupEmail' ? value = (value || '').toLowerCase() : null
    key === 'signupTel' ? value = this.maskPhone(value) : null

    const newState = { [key]: value }

    this.setState(newState)
  }

  // section = 'login' or 'signup'
  setActionValidation(section, validationData) {
    this.setState({
      loginValidation: false,
      signupValidation: false,
      isSubmitingRecover: false,
      isSubmitingLogin: false,
      isSubmitingSignup: false,
      [`${section}Validation`]: { ...validationData }
    })
  }

  maskPhone(v) {
    let r = v.replace(/\D/g, '')
    r = r.replace(/^0/, '')
    if (r.length > 11) {
      // 12+ digitos. Formata como +55 (31) 9999-9999
      r = r.replace(/^(\d{2})(\d\d)(\d{5})(\d{4}).*/, '+$1 ($2) $3-$4')
    } else if (r.length > 5) {
      // 6...11 digitos. Formata como (31) 9999-9999
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3')
    } else if (r.length > 2) {
      // 3...5 digitos. Formata como (31) 9999
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
    } else {
      // 0...2 digitos. Apenas adiciona o primeiro parêntese
      r = r.replace(/^(\d*)/, '($1')
    }
    return r
  }

  async submitSignup(event) {
    event.preventDefault()
    this.setState({ isSubmitingSignup: true })

    const result = await API.postSignup(this.props.hash, {
      name: this.state.signupName,
      tel: this.state.signupTel,
      email: this.state.signupEmail,
      password: this.state.signupPassword,
      url_origin: 'true',
    })

    if (!result.error) {
      window.location = `/s/${result.newHash}`
      return
    }

    if (result.error === 'INVALID_EMAIL') {
      return this.setActionValidation('signup', {
        email: {
          validateStatus: 'error', help: 'Email inválido!'
        }
      })
    }

    if (result.error === 'CLIENT_EXISTS') {
      return this.setActionValidation('signup', {
        alert: {
          message: 'Você já possui o cadastro no sistema! Utilize a função "Esqueci a senha" caso não lembre.',
          type: 'warning',
          showIcon: true,
        },
        email: {
          validateStatus: 'error',
          help: 'Email já cadastrado!'
        }
      })
    }

    if (result.error === 'INVALID_PASSWORD') {
      return this.setActionValidation('signup', {
        password: { validateStatus: 'error', help: 'Senha muito curta! A senha deve ter ao menos 4 caracteres!' },
      })
    }

    if (result.error === 'INVALID_NAME') {
      return this.setActionValidation('signup', {
        name: { validateStatus: 'error', help: 'É necessário informar o seu nome!' },
      })
    }

    if (result.error === 'EXCEPTION') {
      return this.setActionValidation('signup', {
        alert: {
          message: 'Não foi possível cadastrar!',
          type: 'error',
          showIcon: true
        }
      })
    }
  }

  render() {
    return (
      <>
        <div className="dialog-header">
          <div className="dialog-title" id="rcDialogTitle0">
            Cadastrar
          </div>
        </div>
        <SignupForm
          values={this.state}
          onChange={this.onChange}
          onSubmit={this.submitSignup.bind(this)}
          cancel={this.props.cancel}
        />
      </>
    )
  }

}


function SignupForm({ values, onChange, onSubmit, cancel }) {
  const signupValidation = !!values.signupValidation && values.signupValidation

  return (
    <Form layout="vertical" onSubmit={onSubmit}>
      <div className='dialog-body'>

        {!!signupValidation.alert && (<Alert {...signupValidation.alert} />)}
        <Form.Item label="Nome" key="signupName" {...((signupValidation && signupValidation.name) || {})}>
          <Input
            name="signupName"
            value={values.signupName}
            onChange={onChange}
            prefix={(
              <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
            )}
          />
        </Form.Item>
        <Form.Item label="Whatsapp/Telefone" key="signupTel" {...((signupValidation && signupValidation.tel) || {})}>
          <Input
            name="signupTel"
            value={values.signupTel}
            onChange={onChange}
            prefix={(
              <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
            )}
          />
        </Form.Item>
        <Form.Item label="E-mail" key="signupEmail" {...((signupValidation && signupValidation.email) || {})}>
          <Input
            name="signupEmail"
            value={values.signupEmail}
            onChange={onChange}
            autoCapitalize='off'
            style={{ textTransform: 'lowercase' }}
            prefix={(
              <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
            )}
          />
        </Form.Item>
        <Form.Item label="Senha" key="signupPassword" {...((signupValidation && signupValidation.password) || {})}>
          <Input.Password
            name="signupPassword"
            value={values.signupPassword}
            onChange={onChange}
            type="password"
            prefix={(
              <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
            )}
          />
        </Form.Item>
      </div>
      <div className="dialog-footer">
        <Button className="sf-dialog-btn" onClick={cancel}>Voltar</Button>
        <Button className="sf-dialog-btn" htmlType="submit" loading={values.isSubmitingSignup} type="primary">
          <>Cadastrar</>
        </Button>
      </div>
    </Form>
  )
}

SignupForm.propTypes = {
  values: PropTypes.any,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  cancel: PropTypes.func
}
